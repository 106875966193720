<template>
	<v-sheet>
		<h3 class="font-weight-light">Recordings</h3>
		<p>
			You can download recordings of any interviews conducted using our
			integrated meeting tool below. Please note that downloads may take up
			to 2 hours to become available after the interview ends.
		</p>
		<project-info :id="id"></project-info>
		<storage-list
			name="Recordings"
			:allow-upload="false"
			:folder="`projects/${id}/clients/recordings`"
		></storage-list>
		<project-navigation :id="id"></project-navigation>
	</v-sheet>
</template>

<script>
import StorageList from "@c/storage/StorageList.vue";
import ProjectInfo from "@c/project/ProjectInfo";
import ProjectNavigation from "@c/project/ProjectNavigation.vue";
export default {
	name: "Recordings",
	props: {
		id: { type: String },
	},
	components: {
		ProjectNavigation,
		StorageList,
		ProjectInfo,
	},
};
</script>
