<template>
		<v-expansion-panels class="mb-12" flat v-model="open">
			<v-expansion-panel class="ml-1">
				<v-expansion-panel-header class="ma-0 pa-0 text--secondary">{{
					open == 0 ? "Less" : "More"
				}}</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-card flat color="lightest">
						<v-card-text>
							<v-row>
								<v-col cols="2">
									Project description:
								</v-col>
								<v-col>
									<p class="mt-4">{{ project.description }}</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="2">
									Dates:
								</v-col>
								<v-col>
									<project-dates v-model="project.id"></project-dates>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="2">
									Contacts:
								</v-col>
								<v-col>
									<a>henrik.moeller@m.works</a>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
</template>
<style>
.v-expansion-panel-content__wrap {
	margin: 0 !important;
	padding: 0 !important;
}



</style>
<script>
import ProjectDates from "@c/dashboard/ProjectDates";
export default {
	name: "ProjectInfo",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			open: null,
		};
	},
	components: {
		ProjectDates,
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
	},

};
</script>
